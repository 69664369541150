import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class WebRequestService {
  readonly ROOT_URL;
  header;

  constructor(private http: HttpClient) {
    this.ROOT_URL = environment.API_HOST;
  }

  get(url: string) {
    return this.http.get(`${this.ROOT_URL}/${url}`);
  }

  post(url: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${url}`, payload);
  }

  patch(uri: string, payload: Object) {
    return this.http.patch(`${this.ROOT_URL}/${uri}`, payload);
  }

  delete(url: string) {
    return this.http.delete(`${this.ROOT_URL}/${url}`);
  }

  getApiHost() {
    return environment.API_HOST;
  }

  getModules(id) {
    return this.http.get(
      `${"https://dev-licensing.pwm.softwebpages.com/v1/api/modules"}/${id}`
    );
  }
}
