import { Injectable } from "@angular/core";
import { WebRequestService } from "../web-request.service";

@Injectable({
  providedIn: "root",
})
export class PagesService {
  constructor(private webReqService: WebRequestService) {}
  getLists(resource) {
    return this.webReqService.get(resource);
  }

  getOne(resource, id) {
    return this.webReqService.get(`${resource}/${id}`);
  }

  createOne(resource, payload) {
    return this.webReqService.post(`${resource}`, payload);
  }

  updateOne(resource, id, payload) {
    return this.webReqService.patch(`${resource}/${id}`, payload);
  }

  deleteOne(resource, id) {
    return this.webReqService.delete(`${resource}/${id}`);
  }

  uploadImage(file, resource, id) {
    return this.webReqService.post(`${resource}/image/${id}`, file);
  }

  uploadGallery(resource, id, files) {
    return this.webReqService.patch(`${resource}/gallery/${id}`, files);
  }
  uploadImageFromEditor(file, resource, id) {
    return this.webReqService.post(`${resource}/editor/${id}`, file);
  }


  getModules(id) {
    return this.webReqService.getModules(id);
  }
}
