// import { Component, OnDestroy, AfterViewInit, Output, EventEmitter, ElementRef } from '@angular/core';
// import { LocationStrategy } from '@angular/common';

// @Component({
//   selector: 'ngx-tiny-mce',
//   template: '',
// })
// export class TinyMCEComponent implements OnDestroy, AfterViewInit {

//   @Output() editorKeyup = new EventEmitter<any>();

//   editor: any;

//   constructor(
//     private host: ElementRef,
//     private locationStrategy: LocationStrategy,
//   ) { }

//   ngAfterViewInit() {
//     tinymce.init({
//       target: this.host.nativeElement,
//       plugins: ['link', 'paste', 'table'],
//       skin_url: `${this.locationStrategy.getBaseHref()}assets/skins/lightgray`,
//       setup: editor => {
//         this.editor = editor;
//         editor.on('keyup', () => {
//           this.editorKeyup.emit(editor.getContent());
//         });
//       },
//       height: '320',
//     });
//   }

//   ngOnDestroy() {
//     tinymce.remove(this.editor);
//   }
// }
import {
  Component,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter,
  ElementRef,
  Input,
} from "@angular/core";
import { LocationStrategy } from "@angular/common";
import { PagesService } from "app/pages/pages.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "ngx-tiny-mce",
  template: "",
})
export class TinyMCEComponent implements OnDestroy, AfterViewInit {
  @Output() editorKeyup = new EventEmitter<any>();
  @Input() resource = "editor";
  @Input() dataId: string;
  @Input() control: FormControl;

  editor: any;

  constructor(
    private host: ElementRef,
    private locationStrategy: LocationStrategy,
    private pagesService: PagesService
  ) {}

  ngAfterViewInit() {
    tinymce.init({
      target: this.host.nativeElement,
      plugins: [
        "link",
        "paste",
        "table",
        "image",
        "imagetools",
        "media",
        "preview",
        "autoresize",
        "code",
        "textcolor",
        "colorpicker",
        "fullpage",
        "fullscreen",
      ],
      // toolbar:
      // "undo redo | styleselect | bold italic| fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | link image",

      toolbar: [
        "insertfile undo redo | styleselect | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor emoticons",
        "link image media | code preview fullscreen",
      ],
      skin_url: `${this.locationStrategy.getBaseHref()}assets/skins/lightgray`,
      setup: (editor) => {
        this.editor = editor;

        const value = this.control.value;
        editor.on("init", function (e) {
          editor.setContent(value);
        });

        editor.on("keyup", () => {
          this.editorKeyup.emit(editor.getContent());
        });
      },
      height: "420",
      file_picker_types: "image",
      images_upload_handler: (blobInfo, success, failure) => {
        console.log(blobInfo);

        const uploadData = new FormData();

        uploadData.append("img", blobInfo.blob(), blobInfo.filename());

        this.pagesService
          .uploadImageFromEditor(
            uploadData,
            this.resource,
            this.dataId || "editor"
          )
          .subscribe((res: any) => {
            success(res.url);
            this.editorKeyup.emit(this.editor.getContent());
          });
      },
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");

        /*
          Note: In modern browsers input[type="file"] is functional without
          even adding it to the DOM, but that might not be the case in some older
          or quirky browsers like IE, so you might want to add it to the DOM
          just in case, and visually hide it. And do not forget do remove it
          once you do not need it anymore.
        */

        input.onchange = function () {
          // @ts-ignore
          var file = this.files[0];

          var reader = new FileReader();
          reader.onload = function () {
            /*
              Note: Now we need to register the blob in TinyMCEs image blob
              registry. In the next release this part hopefully won't be
              necessary, as we are looking to handle it internally.
            */
            var id = "blobid" + new Date().getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            // @ts-ignore
            var base64 = reader.result.split(",")[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            /* call the callback and populate the Title field with the file name */
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
    });
  }

  ngOnDestroy() {
    tinymce.remove(this.editor);
  }
}
